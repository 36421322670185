@import "../variables.scss";
@import "../Header/Header.scss";

// $header-height: 74px;

.contactMenu {
	display: none;
	opacity: 0;
	height: auto;
	position: fixed;
	top: 74px;
	right: 0px;
	z-index: 2;
	background-color: white;
	width: 80%;
	height: calc(100% - #{$header-height});
	z-index: 3;

	color: $primary-color;
	text-align: left;

	section {
		&.heading, &.contactDetails {
			min-height: 60px;
			line-height: 60px;
			border-bottom: 1px solid #ebebeb!important;
			box-sizing: border-box;
			border-right: 4px #fff solid;
			transition: all .3s ease;
			position: relative;
			padding: 0px 15px;
		}
	}

	h1 {
		font-weight: 400;
		margin: 9px 0px 18px;
	}

	br {
		content: "";
		margin: 3em;
		display: block;
	}


	a {
		font-size: .875rem;
		color: #4d738a;
    text-decoration: none;

		&.phoneIcon::before {
			content: url("../../assets/phoneIcon.svg");
			position: absolute;
			top: 5%;
			width: 30px;
			right: 20px;
		}

		&.contactIcon::before {
			content: url("../../assets/contactIcon.svg");
			position: absolute;
			top: 63%;
			width: 30px;
			height: 30px;
			right: 20px;
		}


		span {
			display: block;
			line-height: 1.4em;
			margin: 10px 0px;

			&:first-child {
				margin-top: 20px;
			}

			&:last-child {
				margin-bottom: 10px;
			}
		}

		.textLarge {
    	font-size: 1.4em;
		}
	}

	@media (min-width: $screen-sm-min) {
		display: none !important;
	}

	// Start animation properties
	transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
	transform: translateX(100%);

	&.closed {
		display: block;
		opacity: 0;
		transform: translateX(100%);
	}

	&.open {
		display: block;
		opacity: 1;
		transform: translateX(0);
	}

}
