@import "../variables.scss";
$button-padding: 10px 30px;
$button-padding-small: 10px 10px;
$button-border-radius: 4px;
$font-size: 22px;
$font-size-smallest: 14px;
$font-size-small: 18px;
$font-size-large: 32px;

.SummaryContainer {
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;

	.Summary {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;
		align-items: center;
		height: calc(100vh - 160px);
		box-sizing: border-box;
		background: black;

		@media (min-width: $screen-sm-min) {
			height: calc(100vh - 74px);
		}

		@media (min-width: $screen-ml-min) {
			align-content: flex-start;
			height: calc(100vh - 160px);
		}

		.questionCopy {
			font-family: $font-family-book;
			white-space: nowrap;
			position: absolute;
			border-radius: $button-border-radius;
			background-color: $question-background-color;
			color: $primary-color;
			padding: $button-padding;
			left: 50%;
			transform: translateX(-50%);
			top: 0px;
			margin-top: 12vh;
			padding: $button-padding-small;
			user-select: none;
			font-size: $font-size-smallest;
			pointer-events: none;

			@media (min-height: 812px) {
				font-size: $font-size-small;
				margin-top: 14vh;
			}

			@media (min-width: $screen-ml-min) {
				margin-top: 19vh;
				padding: $button-padding;
				font-size: $font-size;
			}

			@media (min-width: $screen-sm-min) {
				font-size: $font-size;
				margin-top: 16vh;
			}

			@media (min-width: $screen-md-min) {

			}

			@media (min-width: $screen-ml-min) {
				font-size: $font-size-large;
				margin-top: 20vh;
			}
		}

		.overviewAnswers {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-evenly;
			align-items: center;
			box-sizing: border-box;
			height: 100%;
			width: 100%;
			animation: fadein 0.8s ease-in-out;

			@keyframes fadein {
				from {
					opacity: 0;
				}

				to {
					opacity: 1;
				}
			}

			.answerContainer {
				width: 50%;
				height: 25%;
				background-size: cover;
				background-position: center center;
				position: relative;
				padding: 10px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: center;

				@media (min-width: $screen-sm-min) {
					align-items: end;
				}

				.answerCopy {
					font-family: $font-family-book;
					color: $copy-color;
					padding: 12px 15px;
					margin-top: auto;
					display: inline-block;
					border-radius: 5px;
					text-align: center;
					font-size: 12px;
					line-height: 1.4em;

					&.purple {
						background-color: $answer-one;
					}

					&.orange {
						background-color: $answer-two;
					}

					@media (min-width: $screen-sm-min) {
						font-size: 15px;
					}

					@media (min-width: $screen-lg-min) {
						padding: 14px 17px;
						font-size: 20px;
					}
				}

				@media (min-width: $screen-sm-min) {
					width: 33.33%;
					height: 33.33%;
				}

				@media (min-width: $screen-ml-min) {
					width: 25%;
					height: 50%;
				}
			}
		}

		.showPopup {
			background: $primary-color;
			width: 100%;
			padding: 20px;
			display: flex;
			flex: 1;
			justify-content: center;
			box-sizing: border-box;
			height: 86px;

			&.tablet {
				display: none;
				width: 33.33%;
				height: 33.33%;
				box-sizing: border-box;
				text-align: left;
				padding: 18px;
				display: none;

				@media (min-width: $screen-sm-min) {
					display: block;
				}

				@media (min-width: $screen-ml-min) {
					display: none;
				}
			}

			&.mobile {
				@media (min-width: $screen-sm-min) {
					display: none;
				}

				@media (min-width: $screen-ml-min) {
					width: 100%;
					padding: 20px;
					height: 86px;
					display: flex;
					flex: 1;
				}
			}

			.storyCopy {
				font-family: $font-family-book;
				color: $copy-color;
				display: none;
				width: 100%;
				font-size: 19px;
				line-height: 1.4em;

				@media (min-width: $screen-sm-min) {
					display: block;
					margin-top: 40px;
				}

				@media (min-width: $screen-ml-min) {
					display: none;
					margin-top: 0;
				}
			}

			.showPopupButton {
				font-family: $font-family-base;
				color: $copy-color;
				background-color: $button-color;
				border: none;
				border-radius: 5px;
				font-size: 16px;
				padding: 15px 20px;
				line-height: 1em;
				cursor: pointer;
				user-select: none;
				-webkit-tap-highlight-color: transparent;

				&:hover {
					background-color: $button-hover-color;
				}

				&.tablet {
					font-size: 14px;
				}
			}
		}
	}
}
