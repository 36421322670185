@import '../variables.scss';

$footer-color: #4d738a;
$footer-hover-color: #007fa4;

.footer {
  justify-content: center;
  background-color: #ebebeb;
  color: $footer-color;
  text-align: left;
  display: none;

  @media (min-width: $screen-sm-min) {
    display: flex;
  }

  h2 {
    font-family: $font-family-book-bold;
    font-size: 20px;
    line-height: 25px;
    margin: 48px auto 16px;
    word-wrap: break-word;
    font-style: normal;

    a {
      color: $footer-color;
      font-size: 20px;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  a {
    color: $footer-color;
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;

    &:hover {
      text-decoration: underline;
      color: $footer-hover-color;
    }

  }

  // Columns & Rows
  .row {
    display: flex;
    flex: 1;
    padding-bottom: 64px;
    margin: 0px 15px 80px 15px;
    border-bottom: 1px solid $footer-color;

    @media (min-width: $screen-sm-min) {
      max-width: 970px;
    }

    @media (min-width: $screen-md-min) {
      max-width: 1170px
    }
  }

  .col-sm-4 {
    width: 33.33333333%;

    &.padding {
      margin: 0px 25px;
    }
  }
}