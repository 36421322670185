@import '../variables.scss';

.Suggestions {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  .infoContainer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-end;
    height: auto;
    overflow: visible;
    border-radius: 10px;
    max-width: 660px;
    background-color: rgba(18, 115, 143, 0.8);
    flex: 1;
    font-size: 13px;
    margin: auto 10px;
    position: relative;
    min-height: 212px;

    @media (min-width: $screen-xs-min) {
      font-size: 15px;
      align-items: center;
      margin: auto 20px;
      height: 300px;
    }

    @media (min-width: $screen-sm-min) {
      margin: 0;
    }

    .magazineContainer {
      position: absolute;
      bottom: 15px;
      right: 15px;

      @media (min-width: $screen-sm-min) {
        margin-right: 30px;
        position: relative;
        bottom: 0;
        right: 0;
      }
    }

    .contentContainer {
      justify-content: flex-start;
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      height: 85%;
      width: 100%;
      padding-right: 160px;
      margin: auto 7px 15px 15px;

      @media (min-width: $screen-xs-min) {
        margin: auto 10px 27px 15px;
        width: 45%;
        padding-right: 0;
      }

      @media (min-width: $screen-sm-min) {
        width: 54%;
        margin: auto 10px 27px 30px;
      }

      h1,
      p {
        font-family: $font-family-book;
        color: $copy-color;
        white-space: pre-line;
        margin: 0 0 15px 0;
      }

      h1 {
        font-size: 20px;
        margin-top: 15px;
        margin-bottom: 10px;

        @media (min-width: $screen-xs-min) {
          margin-top: 0;
          font-size: 1.67em;
        }

        @media (min-width: $screen-sm-min) {
          font-size: 2em;
        }
      }

      button {
        font-family: $font-family-base;
        color: $copy-color;
        background-color: $button-color;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        padding: 15px 20px;
        line-height: 1em;
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

        &:hover {
          background-color: $button-hover-color;
        }
      }
    }

    .copy {
      font-family: $font-family-book;
      font-size: 18px;
      color: $headline-color;
    }

    .extraInfo {
      background: $primary-color-partly-transparent;
      color: $copy-color;
      font-family: $font-family-book;
      font-size: 14px;
      line-height: 1.4em;
      padding: 10px;
      border-radius: 10px;
      margin-top: -10px;
    }
  }

  .suggestionFooter {
    background: $primary-color;
    width: 100%;
    padding: 20px;
    position: absolute;
    bottom: 0px;
    justify-content: center;
    box-sizing: border-box;
    height: 86px;

    .hideSuggestionsButton {
      font-family: $font-family-base;
      color: $copy-color;
      font-size: 16px;
      padding: 15px 20px;
      line-height: 1em;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}