@import "../variables.scss";

$phoneWidth: 262px;
$phoneHeight: 437px;
$imagePadding: 55px;
$mobileScale: 0.54;
$tabletScale: 0.8;

.magazine {
  width: auto;
  height: auto;
  position: relative;

  img {
    pointer-events: none;
    left: 0px;
    top: 0px;
    height: auto;
    z-index: 1;
    width: calc(#{$phoneWidth} * #{$mobileScale});
    height: auto;
    display: block;

    @media (min-width: $screen-xs-min) {
      box-shadow: none;
      width: calc(#{$phoneWidth} * #{$tabletScale});
    }

    @media (min-width: $screen-sm-min) {
      width: $phoneWidth;
    }
  }
}