@import "../variables.scss";
$button-padding: 10px 30px;
$button-padding-small: 10px 10px;
$button-border-radius: 4px;
$font-size: 22px;
$font-size-small: 18px;
$font-size-large: 32px;
$transitionAnimation: all 0.3s ease-in-out;

.questionContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  position: absolute;
  color: $copy-color;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  animation: fadein 0.3s ease-in-out;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.visibile {
    opacity: 1;
  }

  &.invisible {
    opacity: 0;
  }

  .questionCopy {
    font-family: $font-family-book;
    white-space: nowrap;
    position: absolute;
    border-radius: $button-border-radius;
    background-color: $question-background-color;
    color: $primary-color;
    padding: $button-padding;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 35vh;
    padding: $button-padding-small;
    z-index: 2;
    user-select: none;
    font-size: $font-size-small;
    pointer-events: none;

    @media (orientation: portrait) {
      margin-top: 20vh;
    }

    @media (min-width: $screen-xs-min) {
      margin-top: 20vh;
      padding: $button-padding;
      font-size: $font-size;
    }

    @media (min-width: $screen-md-min) {
      font-size: $font-size-large;
    }
  }

  &:hover {
    .totalAnswers {
      opacity: 0.7;
    }
  }

  .totalAnswers {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    padding: $button-padding-small;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-color: black;
    transition: $transitionAnimation;
    position: relative;

    @media (min-width: $screen-xs-min) {
      padding: $button-padding;
    }

    &:hover {
      opacity: 1;
      padding: 10px 20px;
      z-index: 1;

      @media (min-width: $screen-xs-min) {
        padding: 10px 40px;
      }
    }

    &.notClickedAnswer {
      z-index: 0;
      opacity: 0.5;
      pointer-events: none;

      .answerButton {
        transition: $transitionAnimation;
        opacity: 0;
      }
    }

    &.clickedAnswer {
      padding: 0 60px;
      z-index: 1;
      transition: all 0.5s ease-in-out;
      pointer-events: none;

      @media (min-width: $screen-sm-min) {
        padding: 0 100px;
      }
    }

    .answerButton {
      pointer-events: none;
      padding: $button-padding-small;
      border-radius: $button-border-radius;
      z-index: 1;
      font-family: $font-family-book;
      user-select: none;
      will-change: transform;
      transition: $transitionAnimation;
      width: fit-content;
      display: table;
      max-width: 35vw;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: $screen-xs-min) {
        top: 46%;
        max-width: 40vw;
      }
    }

    &.answerOne {
      transform-origin: left center;

      .answerButton {
        background-color: $answer-one;
      }
    }

    &.answerTwo {
      transform-origin: right center;

      .answerButton {
        background-color: $answer-two;
      }
    }
  }
}