@import "../variables.scss";

$header-height: 74px;

.header {
	display: flex;
	flex: 0 1 auto;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	height: $header-height;

	@media (min-width: $screen-sm-min) {
		max-width: 1175px;
	}

	img.logo {
		width: 110px;
		margin: 3px 0px;
		cursor: pointer;
	}

	.contact {
		$width: 28px;
		$height: 28px;
		width: $width;
		height: $height;
		margin-right: 15px;

		@media (min-width: $screen-sm-min) {
			display: none;
		}

		.contactButton {
			position: absolute;
			width: $width;
			height: $height;


			.phoneIcon,
			.closeIcon {
				position: absolute;
				top: 0px;
				left: 0px;
				transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
			}

			.closeIcon {
				opacity: 0;
				transform: scaleY(0);
			}

			&.closed>.phoneIcon {
				opacity: 1;
				transform: scaleY(1);
			}

			&.closed>.closeIcon {
				opacity: 0;
				transform: scaleY(0.5);
			}

			&.open>.closeIcon {
				opacity: 1;
				transform: scaleY(1);
			}

			&.open>.phoneIcon {
				opacity: 0;
				transform: scaleY(0.5);
			}
		}
	}

	.content {
		display: none;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;
		font-size: 1rem;

		@media (min-width: $screen-sm-min) {
			display: flex;
		}

		.overlijdenMelden {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: $dela-purple;
			color: $copy-color;
			padding: 10px;
			border-radius: 4px;
			margin: 0px 10px;

			@media (min-width: $screen-xxs-min) {
				flex-direction: row;
			}

			.telephoneNumber {
				margin-left: 5px;
				margin-top: -2px;
				font-size: 1rem;
				color: #fff;
				text-decoration: underline;
				cursor: pointer;

				&:hover {
					text-decoration: none;
				}

				@media (min-width: $screen-xs-min) {
					font-size: 1.3rem;
				}
			}
		}

		.copy {
			color: $dela-purple;
			margin-right: 20px;
			white-space: nowrap;
			display: none;

			@media (min-width: 570px) {
				display: block;
			}
		}
	}
}