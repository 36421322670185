@import "../variables.scss";

$font-size: 22px;
$font-size-extra-small: 16px;
$font-size-small: 18px;
$font-size-large: 32px;
$button-border-radius: 4px;
$button-padding: 10px 30px;
$button-padding-small: 10px 10px;

.preloader {
	// Make a nice preloader
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	color: $copy-color;
	font-size: 2em;
}

.quizContainer {
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	font-size: $font-size-small;
	font-family: $font-family-base;
	overflow: hidden;
	background: black;
	position: relative;

	@media (min-width: $screen-xs-min) {
		font-size: $font-size;
	}

	@media (min-width: $screen-md-min) {
		font-size: $font-size-large;
	}
}