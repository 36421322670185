@import "../variables.scss";

$bullet-margin: 20px 10px;
$bullet-margin-small: 20px 4px;
$font-size-extra-small: 16px;
$font-size-small: 18px;

.bulletContainer {
  font-family: $font-family-book;
  position: absolute;
  display: flex;
  bottom: 0vh;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  width: auto;

  @media (min-width: $screen-xs-min) {
    background-color: transparent;
  }

  .bullet {
    justify-content: center;
    align-content: center;
    font-size: 0.65em;
    color: $copy-color;
    margin: $bullet-margin-small;
    width: 27px;
    height: 27px;
    min-width: 27px;
    padding: 2px 0 0;
    font-size: $font-size-extra-small;
    border: 2px solid white;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;

    &.active {
      background-color: $copy-color;
      color: $primary-color;
    }

    &.notClickable {
      opacity: 0.3;
      cursor: default;
    }

    @media (min-width: $screen-xs-min) {
      width: 30px;
      height: 30px;
      min-width: 30px;
      padding: 4px 0 0;
    }

    @media (min-width: $screen-sm-min) {
      width: 35px;
      height: 35px;
      min-width: 35px;
      padding: 4px 0 0;
      margin: $bullet-margin;
      font-size: $font-size-small;
    }
  }
}
