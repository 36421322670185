@font-face {
  font-family: 'ScalaSans-Regular';
  src: local('ScalaSans-Regular'),
    url(./assets/fonts/ScalaSans-Regular.ttf) format('truetype'),
    url(./assets/fonts/ScalaSans-Regular.woff) format('woff'),
    url(./assets/fonts/ScalaSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'ScalaOT';
  src: local('ScalaOT'),
    url('./assets/fonts/ScalaOT.woff2') format('woff2'),
    url('./assets/fonts/ScalaOT.woff') format('woff'),
    url('./assets/fonts/ScalaOT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ScalaOT-Bold';
  src: local('ScalaOT-Bold'),
    url('./assets/fonts/ScalaOT-Bold.woff2') format('woff2'),
    url('./assets/fonts/ScalaOT-Bold.woff') format('woff'),
    url('./assets/fonts/ScalaOT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
