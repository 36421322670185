 /*
 * Typography
 * ========================================================================== */
 $font-family-base: "ScalaSans-Regular";
 $font-family-book: "ScalaOT";
 $font-family-book-bold: "ScalaOT-Bold";

 /*
 * Colors
 * ========================================================================== */
 $primary-color: #12738F;
 $primary-color-partly-transparent: rgba(0, 127, 164, 0.8);
 $answer-one: rgba(72, 14, 102, 0.9);
 $answer-two: rgba(237, 117, 34, 0.9);
 $button-color: #ED7522;
 $button-hover-color: #8d471f;
 $headline-color: #C89211;
 $copy-color: #FFFFFF;
 $question-background-color: #FFFFFF;
 $dela-purple: #480e66;

 /*
 * Other
 * ========================================================================== */
 $opacity-blocks: 0.8;

 /*
 * Media queries breakpoints
 * ========================================================================== */

 $screen-xxs-min: 360px;
 /* Extra exrra small screen / phone */
 $screen-xs-min: 480px;
 /* Extra small screen / phone */
 $screen-sm-min: 768px;
 /* Small screen / tablet */
 $screen-md-min: 992px;
 /* Medium screen / desktop */
 $screen-ml-min: 1024px;
 /*Medium Large screen / desktop */
 $screen-lg-min: 1200px;
 /* Large screen / wide desktop */