@import "../variables.scss";

.App {
  font-family: $font-family-base;
  text-align: center;

  .mainContainer {
    display: flex;
    flex-flow: column;
    height: 100vh;
    background: white;
  }
}